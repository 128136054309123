import './Logo.css';

export default function Logo({color}) {
    
    return (
      <>
        
      <div id="logo">
        <svg width="80" height="80" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.76 68.93C47.66 69 47.56 69.07 47.46 69.15C47.15 69.37 46.83 69.59 46.51 69.8C46.29 69.95 46.08 70.11 45.85 70.25C45.78 70.29 45.71 70.33 45.64 70.38C45.6 70.4 45.57 70.43 45.53 70.45C38.87 74.55 30.38 76.28 22.74 74.3C19.34 73.42 14.93 71.04 14.93 66.98C14.93 63.33 19 60.96 24.09 60.96C26.72 60.96 29.52 61.64 32.06 62.82V55.53C29.86 54.6 27.4 54.17 25.19 54.17C20.44 54.17 15.78 56.12 13.49 61.63C12.9 52.05 17.05 45.01 26.97 45.01C34.26 45.01 38.84 48.82 39.35 55.95H47.23C46.98 43.66 39.09 37.3 27.14 37.3C12.73 37.3 5.01001 46.54 5.01001 60.02C5.01001 66.09 6.69001 72.38 11.1 76.75C16.37 81.98 24.02 82.85 31.07 82.66C35.31 82.55 39.52 81.69 43.47 80.17C45.45 79.41 47.36 78.48 49.18 77.39C49.62 77.13 50.1 76.85 50.57 76.56V81.85H59.13V57.44C56.67 60.89 53.46 64.86 47.76 68.93Z" fill={color}/>
            <path d="M101.69 37.43C93.72 37.43 88.89 42.18 86.85 48.87C85.83 41.83 81.17 37.43 73.8 37.43C66.43 37.43 61.34 41.75 59.13 48.11V38.45H50.57V62.79C54.02 59.58 56.14 56.51 57.97 53.87C61.01 49.47 64.39 45.06 70.24 45.06C75.75 45.06 78.46 48.71 78.46 55.06V81.85H87.02V57.35C87.02 50.23 91.51 45.06 98.21 45.06C103.72 45.06 106.43 48.71 106.43 55.06V81.85H114.99V51.92C114.99 43.02 110.24 37.42 101.68 37.42L101.69 37.43Z" fill={color}/>
        </svg>
      </div>

      </>
    );

  }


